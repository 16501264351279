import React, { memo } from "react";
import PaymentIcon from "src/images/payments.png";
import { valueFormat } from "src/helper/valueFormat";
import { convertPaymentMethod } from "src/utils/paymentUtils";
import {
  filteredData,
  getCurrentTransactions,
  combineParseAndFormat,
  // convertDateByTimezone,
  formatDateWithTimeZone
} from "src/utils";

const OrderTransactions = ({ orderData }) => {
  console.log(orderData?.metadata, 'orderData?.metadata')
  return (
    <>
      <hr className="line-header"/>
      {filteredData(orderData?.metadata?.transactions)?.map((transaction) => {
        const emvTagData = combineParseAndFormat(transaction?.paymentInfo?.emvTagData);
        return (
          <div key={transaction?._id} className="payment-container">
            <div>
              <img src={PaymentIcon} alt="payment icon"/>
            </div>
            <div style={{width: "100%"}}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <p className="payment-title">
                  {getCurrentTransactions(transaction?.transactionType)}: by{" "}
                  {convertPaymentMethod(transaction?.tender?.method)}
                </p>
                <p className="payment-title">
                  {valueFormat(`$${Number(transaction?.amount || 0).toFixed(2)}`)}
                </p>
              </div>
              {(transaction?.paymentInfo?.retRef || transaction?.paymentInfo?.retref) && (
                <p className="payment-info">
                  Transaction: {transaction?.paymentInfo?.retRef || transaction?.paymentInfo?.retref}
                </p>
              )}
              {transaction?.createdAt && (
                <p className="payment-title">
                  Date: {formatDateWithTimeZone(
                    transaction?.createdAt,
                    orderData?.metadata?.business?.timezone?.timeZoneId
                  )}
                </p>
              )}
              {transaction?.paymentInfo?.lastfour && (
                <p className="payment-info">
                  Card no: {`XXXX XXXX XXXX ${transaction?.paymentInfo?.lastfour}`}
                </p>
              )}
              {emvTagData ? (
                <>
                  {emvTagData.entry_method && (
                    <p className="payment-info">
                      Entry Method: {emvTagData.entry_method}
                    </p>
                  )}
                  {transaction?.paymentInfo?.authcode && (
                    <p className="payment-info">
                      Auth no: {transaction?.paymentInfo?.authcode}
                    </p>
                  )}
                  {emvTagData?.mode && (
                    <p className="payment-info">
                      Mode: {emvTagData?.mode}
                    </p>
                  )}
                  {emvTagData?.aid && (
                    <p className="payment-info">
                      AID: {emvTagData?.aid}
                    </p>
                  )}
                  {emvTagData?.tvr && (
                    <p className="payment-info">
                      TVR: {emvTagData?.tvr}
                    </p>
                  )}
                  {emvTagData?.iad && (
                    <p className="payment-info">
                      IAD: {emvTagData?.iad}
                    </p>
                  )}
                </>
              ) : (
                  <>
                      {transaction?.paymentInfo?.authcode && (
                          <p className="payment-info">
                              Auth no: {transaction?.paymentInfo?.authcode}
                          </p>
                      )}
                      {transaction?.paymentInfo?.entrymode && (
                          <p className="payment-info">
                            Entry Method: {transaction?.paymentInfo?.entrymode}
                          </p>
                      )}
                  </>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
export default memo(OrderTransactions)