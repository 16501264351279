import { memo } from "react";
import {
  convertDateByTimezone,
  findFailedRefunds, findSuccessRefunds,
  formatDisplayNumber,
  getOrderTypeLabel,
} from "src/utils";

function getFirstLine(address1, address2) {
  if (!address1) return "";

  return address2 ? `${address1} ${address2},` : `${address1},`;
}

function getSecondLine(city, state, zipCode) {
  const cityPart = city ? `${city}, ` : "";
  const stateZip = [state, zipCode].filter(Boolean).join(" ");

  return (cityPart + stateZip).trim();
}

const OrderHeader = ({ orderData, timeZoneId }) => {
  const transactions = orderData?.metadata?.transactions || [];
  const failedRefunds = findFailedRefunds(transactions);
  const successRefunds = findSuccessRefunds(transactions);

  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        {orderData?.displayNumber && (
          <p className="displayNumber">{formatDisplayNumber(orderData?.displayNumber)}</p>
        )}
        {failedRefunds.length > 0 && successRefunds?.length === 0 ? (
          <p className="displayRefund">Refund Declined</p>
        ) : null}
        {successRefunds?.length > 0 && successRefunds ? (
          <p className="displayRefund">Refund</p>
        ) : null}
        {orderData?.metadata?.business && (
            <>
            <p className="companyName">
              {orderData?.metadata?.business?.title || orderData?.metadata?.business?.companyName}
            </p>
            {orderData?.metadata?.business?.addressInfo && (
              <>
                <p className="address_info">
                  {getFirstLine(
                    orderData?.metadata?.business?.addressInfo?.address1,
                    orderData?.metadata?.business?.addressInfo?.address2,
                  )} <br/>
                  {getSecondLine(
                    orderData?.metadata?.business?.addressInfo?.city,
                    orderData?.metadata?.business?.addressInfo?.state,
                    orderData?.metadata?.business?.addressInfo?.zipCode,
                  )}
                </p>
              </>
            )}
            <hr className="line-header"/>
          </>
        )}

        {orderData?.customerInfo?.firstName || orderData?.customerInfo?.name ? (
          <p className="user-message">
            {`Thanks for ordering, ${orderData?.customerInfo?.firstName || orderData?.customerInfo?.name}!`}
          </p>
        ) : (
          <p className="user-message">
            {`Thanks for ordering!`}
          </p>
        )}
        <p className="order-number">
          {orderData?.createdAt ? (
            <>
              {convertDateByTimezone(orderData?.createdAt, timeZoneId) - `Order #${orderData?.orderNumber}`}
            </>
          ) : (
            <>{`Order #${orderData?.orderNumber}`}</>
          )}
        </p>
        <hr className="line-header-long"/>
        <p className="orderType">
          {getOrderTypeLabel(orderData.orderType)}
        </p>
        <hr className="line-header-long"/>
      </div>
    </div>
  );
};
export default memo(OrderHeader);
