
const languageMapping = {
  en: 'en',
  ru: 'ru',
  china: 'china'
}

export const groupByCategory = (data, currentLanguage = 'en') => {
  const languageCode = languageMapping[currentLanguage];
  return data?.reduce((acc, item) => {
    if (item?.name) {
      const categoryId = item.name[languageCode];
      if (!acc[categoryId]) {
        acc[categoryId] = {
          items: [],
          categoryName: item?.name[languageCode],
        };
      }
      acc[categoryId].items.push(item);
    } else {
      acc[item._id] = {
        items: [],
      };
      acc[item._id].items.push(item);
    }
    return acc;
  }, []);
};
