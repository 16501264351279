import React, { Fragment, useEffect, useState } from "react";
import "moment-timezone";
import "src/index.css";
import AuthService from "src/service/api";
import { useNavigate } from "react-router-dom";
import OrderNote from "src/components/Header/OrderNote";
import OrderHeader from "src/components/Order/OrderHeader";
import OrderProduct from "src/components/Order/OrderProduct";
import OrderCharges from "src/components/Order/OrderCharges";
import { groupByCategory } from "src/helper/groupByCategory";
import OrderTransactions from "src/components/Order/OrderTransactions";


export const Order = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({});
  const [timeZoneId, setTimeZoneId] = useState("America/Los_Angeles");
  const productsData = groupByCategory(orderData?.categories);
  const getParams = () => {
    let parsedParams = (url) => {
      return url.split("/");
    };

    const params = parsedParams(window.location.pathname);
    const orderId = params[2];
    return {
      orderId
    }
  }

  useEffect(() => {
    const { orderId } = getParams();

    if (!orderId) {
      navigate("/404");
    }

    const fetchData = async () => {
      try {
        const response = await AuthService.getOrderData(orderId);
        if (response?.data?.data?.metadata?.business) {
          document.title = `Orders co | ${response?.data?.data?.metadata?.business?.companyName}`;
        } else {
          document.title = `Orders co`;
        }
        if (response) {
          setOrderData(response.data.data || {});
          setTimeZoneId(
            response?.data?.data?.metadata?.business?.timezone?.timeZoneId ||
            response?.data?.data?.metadata?.business?.timezone ||
            "America/Los_Angeles"
          );
        }
      } catch (e) {
        document.title = `Orders co`;
        setOrderData({});
        setTimeZoneId("America/Los_Angeles");
      }
    };
    fetchData().then();
  }, []);


  return (
    <>
      {orderData && Object.keys(orderData)?.length > 0 && (
        <div id="receipt">
          <OrderHeader orderData={orderData} timeZoneId={timeZoneId}/>
          {orderData?.specialInstructions && (
            <OrderNote instructions={orderData?.specialInstructions}/>
          )}
          <table id="table">
            <tbody>
            <tr>
              <td className="items">Items:</td>
            </tr>
            {Object.keys(productsData)?.length > 0 ? (
              Object.keys(productsData)?.map((keys, index) => {
                return (
                  <>
                    <Fragment key={keys}>
                      {productsData[keys]?.categoryName && (
                        <>
                          {index !== 0 && <div className="separator" />}
                          <tr style={{ marginBottom: "3px" }}>
                            <td
                              style={{ marginLeft: "29px" }}
                              className="product product-name-longer"
                            >
                              {productsData[keys].categoryName.toUpperCase()}
                            </td>
                            <td
                              className="product product-longer-price"
                              style={{ width: "100%" }}
                            />
                          </tr>
                        </>
                      )}
                    </Fragment>
                    {productsData[keys]?.items?.map((products) =>
                      products?.items?.map((item) => {
                        return (
                          <Fragment key={item?.itemId}>
                            <OrderProduct product={item}/>
                          </Fragment>
                        )
                      })
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )}
            </tbody>
          </table>
          <hr className="line-header"/>
          <table id="table">
            <tbody>
            <OrderCharges orderData={orderData}/>
            </tbody>
          </table>

          {orderData?.metadata?.transactions?.length > 0 && (
            <OrderTransactions orderData={orderData} />
          )}
        </div>
      )}
    </>
  );
};
