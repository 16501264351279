
export const formatTimeForReceipt = (dateString) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Manually extracting the components from the date string
  const year = dateString.substring(0, 4);
  const monthIndex = parseInt(dateString.substring(5, 7), 10) - 1;
  const day = dateString.substring(8, 10);
  let hours = parseInt(dateString.substring(11, 13), 10);
  const minutes = dateString.substring(14, 16);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  const strTime = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
  const month = months[monthIndex];

  return `${strTime} - ${month} ${parseInt(day, 10)}, ${year}`;
};

export function convertDateByTimezone(dateStr, timezone) {
  const [month, day, year] = dateStr.split('.').map(Number);

  const utcDate = new Date(Date.UTC(year, month - 1, day));
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return formatter.format(utcDate);
}

export function formatDateWithTimeZone(dateString, timeZone = 'America/Los_Angeles') {
  const date = new Date(dateString);

  const timeString = date.toLocaleTimeString("en-US", {
    timeZone,
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const dateStringPart = date.toLocaleDateString("en-US", {
    timeZone,
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return `${timeString} - ${dateStringPart}`;
}


export const ORDER_TYPE_LABEL = {
  in_store: 'In Store',
  takeout: 'Takeout',
  phone_order_delivery: 'Phone Order/Delivery',
  phone_order_pickup: 'Phone Order/Pick Up',
};

export const ORDER_TYPE_KEYS = {
  in_store: 'in_store',
  takeout: 'takeout',
  phone_order_delivery: 'phone_order_delivery',
  phone_order_pickup: 'phone_order_pickup',
};

export const DISCOUNT_TYPE_LABEL = {
  fixedAmount: 'fixedAmount',
  percentage: 'percentage',
};

export function getOrderTypeLabel(key) {
  return ORDER_TYPE_LABEL[key];
}

export const STATUS_NAME = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
}
export const TRANSACTION_TYPE = {
  refund: 'Refund',
  purchase: 'Payments'
}

export const getCurrentTransactions = (item) => {
  return TRANSACTION_TYPE[item];
}

export const filteredData = (transactions = []) => {
  return transactions.filter((item) => item?.status?.name !== STATUS_NAME.PENDING)
};

const parseJson = (emvTagData) => {
  if (typeof emvTagData === 'string') {
    try {
      return JSON.parse(emvTagData);
    } catch (error) {
      return null;
    }
  }
  return null;
};

const formatKey = (key) => {
  if (typeof key !== 'string') return key;
  return key.replace(/\s+/g, '_').toLowerCase();
};

const formatAllKeys = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => formatAllKeys(item));
  } else if (data !== null && typeof data === 'object') {
    const formattedObj = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const formattedKey = formatKey(key);
        formattedObj[formattedKey] = formatAllKeys(data[key]);
      }
    }
    return formattedObj;
  }
  return data;
};

export const combineParseAndFormat = (emvTagData) => {
  const parsedData = parseJson(emvTagData);
  if (!parsedData) {
    return null;
  }
  return formatAllKeys(parsedData);
};

export const TRANSACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}

export function findFailedRefunds(transactions) {
  if (transactions.length === 0) return [];
  return transactions.filter(tx =>
    tx.transactionType?.toLowerCase() === TRANSACTION_TYPE.refund.toLowerCase() &&
    tx.status &&
    tx.status.name === TRANSACTION_STATUS.FAIL
  );
}

export function findSuccessRefunds(transactions) {
  if (transactions.length === 0) return [];
  return transactions.filter(tx =>
      tx.transactionType?.toLowerCase() === TRANSACTION_TYPE.refund.toLowerCase() &&
      tx.status &&
      tx.status.name === TRANSACTION_STATUS.SUCCESS
  );
}


export function formatDisplayNumber(number) {
  const num = Number(number);
  if (isNaN(num) || num < 0) {
    return '000';
  }
  return num.toString().padStart(3, '0');
}