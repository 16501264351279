const PAYMENT_LABELS = {
  google_pay: "Google Pay",
  apple_pay: "Apple Pay",
  cash: "Cash",
  card: "External Card",
  reward: "Reward",
  coupon: "Coupon",
  lan: "Card",
  bluetooth: "Card",
  manual: "Manual",
};

const capitalizeFirstLetter = (str = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Convert a payment method key into a human-readable label.
 * @param {string} method - The payment method key
 * @returns {string} - The corresponding label (or a fallback)
 */
export const convertPaymentMethod = (method) => {
  return PAYMENT_LABELS[method] || capitalizeFirstLetter(method) || "";
};
